/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "node_modules/@q-pass/core/styles/neomorph-theme";

.swiper-wrapper{
  display:inline-flex;
}

.swiper-pagination-qpass {
  text-align: center;

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    margin: 0 4px !important;
    background: var(--ion-background-color) !important;
    box-shadow: var(--qpass-swiper-pagination-bullet-box-shadow) !important;
    opacity: 1 !important;
    width: 12px !important;
    height: 12px !important;
  }

  .swiper-pagination-bullet-active {
    margin: 0 4px !important;
    opacity: 1 !important;
    width: 12px !important;
    height: 12px !important;
    background: var(--qpass-background-linear-gradient) !important;
    border: var(--qpass-swiper-pagination-bullet-active-border) !important;
    box-shadow: var(--qpass-swiper-pagination-bullet-active-box-shadow) !important;
  }

}

.remaining-chars {
  position:absolute;
  font-family: Comfortaa;
  bottom: -25px;
  right: 10px;
  padding-top: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #665353;
  }

.info-modal--width-730 {
  --max-width: 730px;
}

.modal-fullscreen {
  .modal-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
  }

  &::part(content) {
    border-radius: 8px;
    background-color: var(--ion-background-color);
    overflow: hidden;
  }

  .point-right {
    background-color: var(--ion-background-color);
    // background-color: red;
    position: absolute;
    right: -14px;
    top: 5px;
    height: 28px;
    width: 28px;
    transform: rotate(45deg);
  }

  ion-header {
    ion-toolbar {

      --min-height: 80px;

      ion-title {
        font-size: 24px;
        padding-left: 30px;
      }

      border-bottom: solid 1px var(--qpass-color-text-main);
    }
  }

  ion-content {
    .info-modal-header {
      margin: 24px 24px 12px 24px;
    }

    .info-modal-body {
      margin: 20px 30px 30px 30px;

      ion-item.qpass-checkbox {
        --inner-padding-start: 0 !important;
        --inner-padding-end: 0 !important;
        --padding-start: 0;
      }

    }
  }

  ion-footer {
    ion-row {
      margin-bottom: 16px;
    }

    ion-row:last-of-type {
      margin-bottom: 0;
    }

  }
}

.ios .modal-fullscreen {
  .modal-wrapper {
      top: 26px;
  }
}

.qpass-element-clickable {
  cursor: pointer;
}
.mdc-dialog__surface {
  background-color: var(--ion-background-color) !important;
}
.cdk-overlay-dark-backdrop {
  background:none!important;
}
input.errorValidation {
  border: red solid thin !important;
}
.errorValidationTextArea {
  border: red solid thin !important;
}
.input-right-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value according to your preference */
  transform: translateY(-50%);
}
.cursor_pointer {
  cursor: pointer;
}
ion-checkbox::part(container) {
  box-shadow: var(--qpass-radio-box-shadow);
  border: none;
  background: var(--ion-background-color);
}

ion-checkbox::part(mark) {
  stroke: #63373A;
}
.select_client_popup {
  &::part(content) {
    min-height: 800px !important;
    max-width: 875px !important;
  }
}

.add_partner_popup {
  &::part(content) {
    min-height: 620px !important;
    max-width: 500px !important;
  }
}


.add_link_popup {
  &::part(content) {
    min-height: 380px !important;
    max-width: 500px !important;
  }
}
.select_community_popup {
  &::part(content) {
    min-height: 600px !important;
    max-width: 875px !important;
  }
}
.offer_qr_image {
  width: 240px;
  height: 240px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dynamic-height-dialog .mat-dialog-container {
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
}

html, body {
  background-color: white !important;
}
