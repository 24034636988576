// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@use "node_modules/@q-pass/core/styles/common" as qpass-core-styles-common;

/** Ionic CSS Variables **/
:root {
  @include qpass-core-styles-common.applyLightTheme();
  
  --ion-font-family: var(--qpass-font-family);

  --ion-text-color: var(--qpass-color-text-main);
  --ion-text-color-rgb: var(--qpass-color-text-main-rgb);
  
  --ion-background-color: var(--qpass-color-background);
  --ion-background-color-rgb: var(--qpass-color-background-rgb);

  /** primary **/
  --ion-color-primary: linear-gradient(135deg, #ed5a5a 0%, #6a0000 100%);
  --ion-color-primary-rgb: 106, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3f0000;
  --ion-color-primary-tint: #9e372a;

  /** secondary **/
  --ion-color-secondary: #f8ebe4;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #26150e;
  --ion-color-secondary-contrast-rgb: 38, 21, 14;
  --ion-color-secondary-shade: #c5b9b2;
  --ion-color-secondary-tint: #ffffff;

  /** tertiary **/
  --ion-color-tertiary: #e78a8a;
  --ion-color-tertiary-rgb: 231,138,138;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #cb7979;
  --ion-color-tertiary-tint: #e99696;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #665353;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
 
  /** extra colors for q-pass **/
  --ion-color-qprimary: #ed5a5a;
  --ion-color-qprimary-rgb: 237, 90, 90;
  --ion-color-qprimary-contrast: #ffffff;
  --ion-color-qprimary-contrast-rgb: 255, 255, 255;
  --ion-color-qprimary-shade: #3f0000;
  --ion-color-qprimary-tint: #9e372a;


  --ion-color-qsecondary: #665353;
  --ion-color-qsecondary-rgb: 102,83,83;
  --ion-color-qsecondary-contrast: #ffffff;
  --ion-color-qsecondary-contrast-rgb: 255,255,255;
  --ion-color-qsecondary-shade: #5a4949;
  --ion-color-qsecondary-tint: #756464;
}

.ion-color-qprimary {
  --ion-color-base: var(--ion-color-qprimary);
  --ion-color-base-rgb: var(--ion-color-qprimary-rgb);
  --ion-color-contrast: var(--ion-color-qprimary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-qprimary-contrast-rgb);
  --ion-color-shade: var(--ion-color-qprimary-shade);
  --ion-color-tint: var(--ion-color-qprimary-tint);
}

.ion-color-qsecondary {
  --ion-color-base: var(--ion-color-qsecondary);
  --ion-color-base-rgb: var(--ion-color-qsecondary-rgb);
  --ion-color-contrast: var(--ion-color-qsecondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-qsecondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-qsecondary-shade);
  --ion-color-tint: var(--ion-color-qsecondary-tint);
}


@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

}

/* Fallback for older browsers or manual mode */

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  @include qpass-core-styles-common.applyDarkTheme();

  --ion-font-family: var(--qpass-font-family);

  --ion-text-color: var(--qpass-color-text-main);
  --ion-text-color-rgb: var(--qpass-color-text-main-rgb);
  
  --ion-background-color: var(--qpass-color-background);
  --ion-background-color-rgb: var(--qpass-color-background-rgb);

  --ion-color-primary: linear-gradient(135deg, #ed5a5a 0%, #6a0000 100%);
  --ion-color-primary-rgb: 106, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #960505;
  --ion-color-primary-tint: #9e372a;

  --ion-color-secondary: #1e1a1a;
  --ion-color-secondary-rgb: 30,26,26;
  --ion-color-secondary-contrast: #f8ebe4;
  --ion-color-secondary-contrast-rgb: 248, 235, 228;
  --ion-color-secondary-shade: #c5b9b2;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #e78a8a;
  --ion-color-tertiary-rgb: 231,138,138;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #cb7979;
  --ion-color-tertiary-tint: #e99696;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** extra colors for q-pass **/
  --ion-color-qsecondary: #f8ebe4;
  --ion-color-qsecondary-rgb: 248,235,228;
  --ion-color-qsecondary-contrast: #000000;
  --ion-color-qsecondary-contrast-rgb: 0,0,0;
  --ion-color-qsecondary-shade: #dacfc9;
  --ion-color-qsecondary-tint: #f9ede7;

}

// .content-web-footer-container {
//   height: 143px;
// }

// .content-web-main-container {
//   min-height: calc(100% - 143px);
// }

.error {
  color: #ff0000 !important;
}

.web-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%
}

.web-page-content-container {
  flex: 1;
}

.web-page-footer-container {
  flex: 0;
}